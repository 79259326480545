<template>
  <div class="stories">
    <div class="stories-banner">
      <div class="stories-banner__content">
        <h1>精选案例</h1>
        <p>Customer Stories</p>
        <p><i>邵逸夫医院 & 浙江省人民医院</i><span></span></p>
      </div>
    </div>
    <div class="stories-shaoyifu">
      <h2>邵逸夫医院</h2>
      <p>
        浙江大学医学院附属邵逸夫医院是由香港知名实业家邵逸夫爵士捐资、浙江省人民政府配套建设，集医疗、教学和科研为一体的公立综合性三级甲等医院。<br />
        1994年5月开业运行。医院现有庆春、钱塘、双菱三个院区，设有47个临床医疗科研部门，112个护理单元。作为浙江大学医学院第三临床医学院，目前有38个博士点和45个硕士点，设有29个教研室和教研组。
      </p>
      <div class="stories-shaoyifu__content">
        <div class="stories-shaoyifu__content-left">
          <img src="@/assets/images/shaoyifu-logo.png" alt="" width="94px" />
          <h5>邵逸夫医院</h5>
          <p>
            以人机结合的质控模式颠覆了人工抽检的模式，为质控管理部门提供信息化工具，自动驳回+自动归档+人工核实质控模式节省83%的人力，同时专家抽检效率提高了10倍左右，极大程度减轻了人工质控负担。
          </p>
        </div>
        <div class="stories-shaoyifu__content-right">
          <img src="@/assets/images/shaoyifu-icon.png" alt="" width="100%" />
        </div>
      </div>
    </div>
    <div class="stories-shengrenmin">
      <h2>浙江省人民医院</h2>
      <p>
        浙江省人民医院是隶属于浙江省卫生和计划生育委员会的一所三级甲等医院<br />浙江省人民医院（浙江省立医院）是浙江省内规模最大的集医疗、科研、教学、预防、保健、康复、疗养于一体的大型综合性三级甲等<br />医院之一，隶属浙江省卫生厅，也是浙江大学医学院的教学医院。
      </p>
      <div class="stories-shengrenmin__content">
        <div class="stories-shengrenmin__content-left">
          <img src="@/assets/images/shengrenmin-icon.png" alt="" width="100%" />
        </div>
        <div class="stories-shengrenmin__content-right">
          <img src="@/assets/images/shengrenmin-logo.png" alt="" width="94px" />
          <h5>浙江省人民医院</h5>
          <p>
            偏重于事前规则提示、事中实时提醒，从源头解决病历质量问题，有效提高病历管理效率，降低管理成本。
          </p>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    foot: () => import("@/components/footer.vue"),
  },
  mounted() {
    this.handleScroll();
  },
  methods: {
    //...mapMutations(["setHomeScrollTop"]),
    handleScroll() {
      //this.setHomeScrollTop(100);
    },
  },
  destroyed() {
    //this.setHomeScrollTop(0);
  },
};
</script>

<style lang="scss" scoped>
.stories {
  h2 {
    font-weight: 600;
    color: #333333;
    font-size: 36px;
  }
  p {
    font-weight: 400;
    color: #000000;
    font-size: 20px;
    margin: 40px 0 64px;
    text-align: left;
    line-height: 35px;
  }
  &-banner {
    height: 458px;
    background: url("../../assets/images/stories-banner.png") no-repeat;
    background-size: cover;
    &__content {
      width: 1200px;
      margin: 0 auto;
      padding-top: 149px;
    }
    h1 {
      font-size: 48px;
      font-weight: 500;
      color: #ffffff;
      margin: 0;
    }
    p {
      font-size: 32px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.65);
      margin: 0;
      &:last-child {
        font-size: 32px;
        font-weight: 500;
        color: #ffffff;
        margin-top: 48px;
        display: inline-block;
        position: relative;
        i {
          position: relative;
          z-index: 99;
        }
        span {
          width: 100%;
          height: 12px;
          background-color: #fbb726;
          position: absolute;
          left: 0;
          bottom: -5px;
          z-index: 9;
        }
      }
    }
  }
  &-shaoyifu {
    width: 1200px;
    margin: 90px auto 0 auto;
    text-align: center;
    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-left {
        flex: 1;
        text-align: left;
        h5 {
          font-size: 24px;
          font-weight: 600;
          color: #000000;
          text-align: left;
          margin: 24px 0 16px;
        }
        p {
          width: 416px;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          text-align: left;
          margin: 0;
          line-height: 23px;
        }
      }
      &-right {
        width: 738px;
      }
    }
  }
  &-shengrenmin {
    width: 1200px;
    margin: 90px auto 60px auto;
    text-align: center;
    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-right {
        flex: 1;
        text-align: left;
        padding-left: 112px;
        h5 {
          font-size: 24px;
          font-weight: 600;
          color: #000000;
          text-align: left;
          margin: 24px 0 16px;
        }
        p {
          width: 416px;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          text-align: left;
          margin: 0;
          line-height: 23px;
        }
      }
      &-left {
        width: 669px;
      }
    }
  }
}
</style>